import { ROUTES } from '@constants/routes';
import { CONTACTS_COMMON_PATH } from '@constants/routes/contacts';
import { LISTS_ROOT_NAME } from '@constants/lists';
import { SALES_CYCLE_PATH, SALES_ACTIVE_CYCLE_PATH } from '@constants/routes/salesCycle';
import { CLIENT_SPECIFIC_TASKS_PATH, TO_DOS_PATH } from '@constants/routes/toDos';
import { MenuPoints } from '@/shared/types/menuItems';

import {
  AccountsAndPlansMenu,
  CoinMenu,
  CalendarMenu,
  ContactsMenu,
  Dashboard,
  TodosMenu,
  TrashMenu,
} from '@/assets';


export const MENU_POINTS: MenuPoints = [
  {
    icon: Dashboard,
    link: ROUTES.dashboard,
    rootLink: ROUTES.dashboard,
    title: 'Dashboard',
    tooltip: 'Dashboard',
  },
  {
    icon: CalendarMenu,
    link: ROUTES.calendar,
    rootLink: ROUTES.calendar,
    title: 'Calendar',
    tooltip: 'Calendar',
  },
  {
    icon: TodosMenu,
    link: CLIENT_SPECIFIC_TASKS_PATH,
    rootLink: TO_DOS_PATH,
    title: 'To-Dos',
    tooltip: 'To-Dos',
  },
  {
    icon: ContactsMenu,
    link: ROUTES.contactsAll,
    rootLink: CONTACTS_COMMON_PATH,
    title: 'Contacts',
    submenu:[
      {
        link: ROUTES.contactsAll,
        title: 'All Contacts'
      },
      {
        link: ROUTES.lists,
        title: LISTS_ROOT_NAME
      }
    ]
  },
  {
    icon: CoinMenu,
    link: SALES_ACTIVE_CYCLE_PATH,
    rootLink: SALES_CYCLE_PATH,
    title: 'Sales Pipeline',
    tooltip: 'Sales Pipeline',
  },
  {
    icon: AccountsAndPlansMenu,
    link: ROUTES.accountsAndPlans,
    rootLink: ROUTES.accountsAndPlans,
    title: 'Accounts & Plans',
    tooltip: 'Accounts & Plans'
  },
  {
    icon: TrashMenu,
    link: ROUTES.trashContacts,
    rootLink: ROUTES.trashContacts,
    title: 'Trash',
    tooltip: 'Trash',
  },
];

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const isTestRender = process.env.NODE_ENV === 'development';

// isTestRender && MENU_POINTS.push({
//   icon: TrashMenu,
//   link: ROUTES.test,
//   rootLink: ROUTES.test,
//   title: 'Test'
// });
